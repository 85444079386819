import ClientAppManagerInterface from "../ClientAppManagerInterface";

class BasePageManager<T, J extends ClientAppManagerInterface> {
  private isInitialized: boolean;
  public pageData: T;
  protected clientAppManager: J;

  public constructor(pageData: T) {
    this.isInitialized = false;
    this.pageData = pageData;
  }

  public setClientAppManager(clientAppManager: J) {
    this.clientAppManager = clientAppManager;
  }

  setPageData(): void {
    this.initialize();
  }

  public initialize(): boolean {
    if (this.isInitialized) {
      return false;
    }
    this.isInitialized = true;
    return true;
  }

  public pageMounted(): void {}

  public reset(): void {
    this.isInitialized = false;
    console.log("App manager reset");
  }
}

export default BasePageManager;
