import React from "react";
import { Heading } from "./FaqsList.styles";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import AccordionController from "@/mvcComponents/Commons/Accordion/Accordion.controller";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import styles from "./FaqsList.module.css";
import { alignmentMapping } from "@/staticData/constant";

function FaqsListView({
  faqs,
  id,
  heading,
  ctaText,
  ctaLink,
  alignment = "center",
}) {
  return faqs?.length ? (
    <ColDiv padding="10px" 
    style={{ alignItems: alignmentMapping[alignment] }}
    >
      <Heading>{heading}</Heading>
      <ColDiv gap="15px" id={id} width="100%">
        {faqs.map((faq, i) => (
          <AccordionController
            key={faq._id}
            defaultOpen={i === 0}
            title={faq.question}
            description={faq.answer}
            setTextAsHtml={false}
            analyticsKey="faq"
          />
        ))}
      </ColDiv>
      {ctaLink && ctaText && (
        <ConditionLinkView link={ctaLink} analytics="faq">
          <div className={styles.cta}>{ctaText}</div>
        </ConditionLinkView>
      )}
    </ColDiv>
  ) : null;
}

export default FaqsListView;
