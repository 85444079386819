import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class BookCallPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/course-book-call";
}

export default BookCallPageActionController;
