import React from "react";
import styles from "./GroupedCategoryCheckbox.module.css";
import CategoryView from "./Category.view";
import GroupedCategoryCheckboxController from "./GroupedCategoryCheckbox.controller";

const groupedCategoryCheckboxControllerObj =
  new GroupedCategoryCheckboxController();

const GroupedCategoryCheckboxView = ({
  value,
  onChange,
  input,
  heading,
  name,
}) => {
  const categories = Object.keys(input);

  const onUserInputChange = ({
    target: { name: changeCategory, value: checked },
  }) => {
    const updatedValue = groupedCategoryCheckboxControllerObj.getUpdatedValue(
      changeCategory,
      checked,
      value
    );

    onChange({ target: { name, value: updatedValue } });
  };

  return (
    <div>
      <p className={styles.heading}>{heading}</p>
      {categories.map((category, index) => {
        const { label, subCategories } = input[category];
        return (
          <CategoryView
            name={category}
            label={label}
            subCategories={subCategories}
            onChange={onUserInputChange}
            groupValue={value}
            key={category}
            openByDefault={index == 0}
          />
        );
      })}
    </div>
  );
};

export default GroupedCategoryCheckboxView;
