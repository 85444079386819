import {
  CHECKBOX,
  MULTI_SELECT,
  DROPDOWN_SELECT,
  RADIO,
  SELECT,
  TEXT,
  PHONE_NUMBER,
  FILE_UPLOAD,
  ANNUAL_COMPENSATION,
  GAME_BUILD_UPLOAD,
  TEXT_AREA,
  HEADING,
  RATING,
  MULTI_SELECT_CHECKBOX,
  GROUPED_CATEGORY_CHECKBOX,
  // RICH_TEXT
} from "../InputTypes";
import InputTextField from "./InputTextField";
import MultiSelectInput from "./MultiSelectInput";
import SelectInput from "./SelectInput";
import RadioInput from "./RadioInput";
import CheckboxInput from "./CheckboxInput";
import FileUploader from "./FileUpload/FileUploader";
import MobileNumberInput from "./MobileNumberInput";
import AnnualCompensationInput from "./AnnualCompensationInput";
import GameBuildUploader from "./GameBuildUpload/GameBuildUploader";
import TextAreaInput from "./TextAreaInput";
import HeadingInput from "./HeadingInput";
import RatingComponent from "./RatingComponent";
import DropdownSelectInput from "./DropdownSelectInput";
import MultiSelectCheckboxInput from "./MultiSelectCheckboxInput";
import GroupedCheckboxView from "./GroupedCategoryCheckbox/GroupedCategoryCheckbox.view";
// import RichTextInput from "./RichTextInput";

let inputs = {};

inputs[TEXT] = InputTextField;
inputs[RATING] = RatingComponent;
inputs[SELECT] = SelectInput;
inputs[MULTI_SELECT] = MultiSelectInput;
inputs[DROPDOWN_SELECT] = DropdownSelectInput;
inputs[RADIO] = RadioInput;
inputs[CHECKBOX] = CheckboxInput;
inputs[PHONE_NUMBER] = MobileNumberInput;
inputs[FILE_UPLOAD] = FileUploader;
inputs[ANNUAL_COMPENSATION] = AnnualCompensationInput;
inputs[GAME_BUILD_UPLOAD] = GameBuildUploader;
inputs[TEXT_AREA] = TextAreaInput;
inputs[HEADING] = HeadingInput;
inputs[MULTI_SELECT_CHECKBOX] = MultiSelectCheckboxInput;
inputs[GROUPED_CATEGORY_CHECKBOX] = GroupedCheckboxView;
// inputs[RICH_TEXT] = RichTextInput;
export default inputs;
