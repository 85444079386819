import dynamic from "next/dynamic";
const LoginPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/LoginPopup/LoginPopup.controller"
  )
);
const InputFormPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/InputFormPopup/InputFormPopup.controller"
  )
);
const RegisterPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/RegisterPopup/RegisterPopup.controller"
  )
);
const ValueCaptureRegisterPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/ValueCaptureRegisterPopup/ValueCaptureRegisterPopup.controller"
  )
);
const ForgotPasswordPopup = dynamic(() =>
  import("@/Components/Popup/AppPopups/ForgotPasswordPopup")
);
const GuestLoginPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/GuestLoginPopup/GuestLoginPopup.controller"
  )
);
const WaitlistConfirmationPopup = dynamic(() =>
  import("@/Components/Popup/WaitlistConfirmationPopup")
);
const ShowAssignmentSubmitPopUp = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/AssignmentPopup/ShowAssignmentSubmitPopUp"
  )
);
const ShowAssignmentSubmissionsPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/AssignmentPopup/ShowAssignmentSubmissionsPopup"
  )
);
const JoinWaitlistTypeformPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/Typeform/JoinWaitlistTypeformPopup")
);
const JobAlertPopup = dynamic(() => import("@/Components/Popup/JobAlertPopup"));
const McqPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/JoinedWaitlistPopups/MCQPopup")
);
const PaymentOptionPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/JoinedWaitlistPopups/Payments/PaymentOptionPopup"
  )
);
const IframePopup = dynamic(() => import("@/Components/Popup/IframePopup"));
const ReportJobPopup = dynamic(() =>
  import("@/Components/Popup/JobPopups/ReportJobPopup")
);
const IGDCPopup = dynamic(() =>
  import("@/Components/Popup/GenericPopups/IGDCPopup")
);
const FeeStructurePopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/FeeStructure/FeeStructurePopup")
);
const EditGameBuildInfoPopup = dynamic(() =>
  import("@/Components/Popup/EditInfoPopups/EditGameBuildInfoPopup")
);
const ResumePopup = dynamic(() =>
  import("@/Components/Popup/GenericPopups/ResumePopup")
);
const ReviewFeedbackPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/ReviewFeedbackPopup/ReviewFeedbackPopup"
  )
);
const ReviewFeedbackAlertPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/ReviewFeedbackPopup/ReviewFeedbackAlertPopup"
  )
);
const WaitlistApplicationCompletedPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/WaitlistApplicationPopup/WaitlistApplicationCompletedPopup"
  )
);
const ApplyToSimilarJobsPopup = dynamic(() =>
  import(
    "@/Components/Popup/JobPopups/ApplyToSimilarJobsPopup/ApplyToSimilarJobsPopup"
  )
);
const ResubmitFailedAssignmentPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/ResubmitFailedAssignmentPopup")
);
const AssessmentPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AssessmentPopup/AssessmentPopup.controller"
  )
);

const JobApplyConfirmationPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/JobApplyConfirmationPopup/JobApplyConfirmationPopup.controller"
  )
);

const EmailInputPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/EmailVerificationPopup/EmailInputPopup/EmailInputPopup.controller"
  )
);
const TokenInputPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/EmailVerificationPopup/TokenInputPopup/TokenInputPopup.controller"
  )
);
const ProUnlockedInfoPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/ProUnlockedInfoPopup/ProUnlockedInfoPopup.controller"
  )
);
const BookACallPopupView = dynamic(() =>
  import("@/mvcComponents/Commons/Popups/BookACallPopup/BookACallPopup.view")
);
const CalendlyAdmissionBookingPopupView = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/CalendlyAdmissionBookingPopup/CalendlyAdmissionBookingPopup.view"
  )
);

import usePopupActions, {
  FORGOT_PASSWORD_POPUP,
  GUEST_LOGIN_POPUP,
  VALUE_CAPTURE_REGISTER_POPUP,
  JOIN_WAITLIST_TYPEFORM,
  LOGIN_POPUP,
  REGISTER_POPUP,
  WAITLIST_POPUP,
  ASSIGNMENT_SUBMIT_POPUP,
  ASSIGNMENT_SUBMISSIONS_POPUP,
  JOB_ALERT_POPUP,
  MCQ_POPUP,
  PAYMENT_OPTION_POPUP,
  IFRAME_POPUP,
  REPORT_JOB_POPUP,
  PHONE_NUMBER_POPUP,
  IGDC_POPUP,
  FEE_STRUCTURE_POPUP,
  EDIT_GAME_BUILD_INFO_POPUP,
  RESUME_POPUP,
  REVIEW_FEEDBACK_POPUP,
  REVIEW_FEEDBACK_ALERT_POPUP,
  WAITLIST_APPLICATION_COMPLETED,
  APPLY_TO_SIMILAR_JOBS_POPUP,
  RESUBMIT_FAILED_ASSIGNMENT_POPUP,
  MESSAGE_POPUP,
  ASSESSMENT_POPUP,
  ADD_SKILLS_POPUP,
  ADS_JOINWAITLIST_POPUP,
  EMAIL_VERIFICATION_EMAIL_INPUT_POPUP,
  EMAIL_VERIFICATION_TOKEN_INPUT_POPUP,
  JOB_APPLY_CONFIRMATION_POPUP,
  INPUT_POPUP,
  INPUT_FORM_POPUP,
  PRO_REGISTER_POPUP,
  PRO_UNLOCKED_INFO_POPUP,
  BOOK_CALL_POPUP,
  CALENDLY_ADMISSION_BOOKING_POPUP,
} from "@/store/actions/popupAction";
import { useEffect, useRef } from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import AdsJoinWaitlistPopup from "@/Components/Popup/AdsJoinWaitlistPopup";
import AddSkillPopup from "@/mvcComponents/Commons/Popups/AddSkillPopup/AddSkillPopup.controller";
import { useRouter } from "next/router";
import InputPopupController from "@/mvcComponents/Commons/Popups/InputPopup/InputPopup.controller";
import PhoneNumberPopup from "@/mvcComponents/CommonUI/Popups/PhoneNumberPopup/PhoneNumberPopup.controller";
import MessagePopup from "@/mvcComponents/Commons/Popups/MessagePopup/MessagePopup.controller";
import ProRegisterPopup from "@/mvcComponents/Commons/Popups/AuthPopups/ProRegisterPopup/ProRegisterPopup.controller";
import { CALENDLY_BOOKINNG_LINK } from "@/staticData/constant";
import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";

const PopupService = () => {
  const { popup, data, showPopup, hidePopups } = usePopupActions();
  const prevUrl = useRef(null);

  const { user } = useUserActions();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (router.query.checkLogin) {
      showPopup(LOGIN_POPUP, {
        heading: "Check Job Application",
        subHeading:
          "Log in to your account to check the status of your job application.",
      });
    }
  }, [user, router.isReady, router.query.clickFrom]);

  useEffect(() => {
    if (router.isReady) {
      if (
        router.query.popup &&
        (popup == null || router.query.popup != popup.toString())
      ) {
        showPopup(router.query.popup);
      } else if (!router.query.popup) {
        hidePopups();
      }
    }
  }, [router.isReady, router.query.popup]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      prevUrl.current = router.asPath;
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const checkAndOpenCourseLeavingPopup = () => {
    const hasUserJoinWaitlisted = user?.userCourseStatus?.find(
      (status) =>
        status?.current_status?.status ==
        UserCourseStatusEnum.getValue("waitlisted")
    );

    if (
      !hasUserJoinWaitlisted &&
      prevUrl.current?.includes("course") &&
      !prevUrl.current?.includes("course-book-call") &&
      !router.pathname.includes("course") &&
      !router.pathname.includes("scholarship") &&
      !router.pathname.includes("welcome") &&
      !router.pathname.includes("/auth") &&
      !router.pathname.includes("/form/") &&
      !router.pathname.includes("/social/") &&
      !router.pathname.includes("/waitlist-confirmation") &&
      !router.pathname.includes("/community/") &&
      !router.pathname.includes("/company/")
    ) {
      showPopup(MESSAGE_POPUP, {
        title: "Need Support Moving Forward?",
        message:
          "We would like to discuss your goals, and how we can help you achieve them",
        ctaText: " Talk to an advisor",
        action: () => {
          showPopup(IFRAME_POPUP, {
            url: CALENDLY_BOOKINNG_LINK,
            analyticsText: "calendly-iframe",
          });
        },
        dismissable: false,
        messageColor: "textDarkerGrey",
        messageFontSize: "xxl",
        width: "80%",
        maxWidth: "500px",
        padding: "30px 25px",
        image:
          "https://outscal-assets.s3.ap-south-1.amazonaws.com/phone-icon.png",
        imageSize: 130,
        analyticsText: "course-leaving-popup",
        dismissable: true,
      });
    }
  };

  useEffect(() => {
    if (!router.pathname.includes("/welcome/"))
      checkAndOpenCourseLeavingPopup();
  }, [router.pathname]);

  return (
    <>
      {popup == ADS_JOINWAITLIST_POPUP && <AdsJoinWaitlistPopup {...data} />}
      {popup == LOGIN_POPUP && <LoginPopup {...data} />}
      {popup == REGISTER_POPUP && <RegisterPopup {...data} />}
      {popup == PRO_REGISTER_POPUP && <ProRegisterPopup {...data} />}
      {popup == VALUE_CAPTURE_REGISTER_POPUP && (
        <ValueCaptureRegisterPopup {...data} />
      )}
      {popup == FORGOT_PASSWORD_POPUP && <ForgotPasswordPopup {...data} />}
      {popup == GUEST_LOGIN_POPUP && <GuestLoginPopup {...data} />}
      {popup == MESSAGE_POPUP && <MessagePopup {...data} />}
      {popup == WAITLIST_POPUP && <WaitlistConfirmationPopup {...data} />}
      {popup == ASSIGNMENT_SUBMIT_POPUP && (
        <ShowAssignmentSubmitPopUp {...data} />
      )}
      {popup === ASSIGNMENT_SUBMISSIONS_POPUP && (
        <ShowAssignmentSubmissionsPopup {...data} />
      )}
      {popup === JOIN_WAITLIST_TYPEFORM && (
        <JoinWaitlistTypeformPopup {...data} />
      )}
      {popup === JOB_ALERT_POPUP && <JobAlertPopup {...data} />}
      {popup === MCQ_POPUP && <McqPopup {...data} />}
      {popup === PAYMENT_OPTION_POPUP && <PaymentOptionPopup {...data} />}
      {popup === IFRAME_POPUP && <IframePopup {...data} />}
      {popup === REPORT_JOB_POPUP && <ReportJobPopup {...data} />}
      {popup == PHONE_NUMBER_POPUP && <PhoneNumberPopup {...data} />}
      {popup === IGDC_POPUP && <IGDCPopup {...data} />}
      {popup === FEE_STRUCTURE_POPUP && <FeeStructurePopup {...data} />}
      {popup === EDIT_GAME_BUILD_INFO_POPUP && (
        <EditGameBuildInfoPopup {...data} />
      )}
      {popup === RESUME_POPUP && <ResumePopup {...data} />}
      {popup === REVIEW_FEEDBACK_POPUP && <ReviewFeedbackPopup {...data} />}
      {popup === REVIEW_FEEDBACK_ALERT_POPUP && (
        <ReviewFeedbackAlertPopup {...data} />
      )}
      {popup == WAITLIST_APPLICATION_COMPLETED && (
        <WaitlistApplicationCompletedPopup {...data} />
      )}
      {popup === APPLY_TO_SIMILAR_JOBS_POPUP && (
        <ApplyToSimilarJobsPopup {...data} />
      )}
      {popup === RESUBMIT_FAILED_ASSIGNMENT_POPUP && (
        <ResubmitFailedAssignmentPopup {...data} />
      )}
      {popup === ASSESSMENT_POPUP && <AssessmentPopupController {...data} />}
      {popup === ADD_SKILLS_POPUP && <AddSkillPopup {...data} />}
      {popup == EMAIL_VERIFICATION_EMAIL_INPUT_POPUP && (
        <EmailInputPopupController {...data} />
      )}
      {popup == EMAIL_VERIFICATION_TOKEN_INPUT_POPUP && (
        <TokenInputPopupController {...data} />
      )}
      {popup == JOB_APPLY_CONFIRMATION_POPUP && (
        <JobApplyConfirmationPopupController {...data} />
      )}
      {popup == INPUT_POPUP && <InputPopupController {...data} />}
      {popup == INPUT_FORM_POPUP && <InputFormPopupController {...data} />}
      {popup == PRO_UNLOCKED_INFO_POPUP && (
        <ProUnlockedInfoPopupController {...data} />
      )}
      {/* {popup == BOOK_CALL_POPUP && (
        <BookACallPopupView {...data} />
      )} */}
      {popup == CALENDLY_ADMISSION_BOOKING_POPUP && (
        <CalendlyAdmissionBookingPopupView {...data} />
      )}
    </>
  );
};

export default PopupService;
