class GroupedCategoryCheckboxController {
  getUpdatedValue = (changeCategory, checked, prevValue) => {
    let updatedValue = { ...prevValue };
    const categoryKeys = changeCategory.split(".");
    let category = categoryKeys[0];
    let subCategory = categoryKeys[1];

    if (subCategory) {
      updatedValue[category].subCategories[subCategory].value = checked;
    } else {
      updatedValue[category].value = checked;
    }

    return updatedValue;
  };
}

export default GroupedCategoryCheckboxController;
