import { useRouter } from "next/router";
import React, { useEffect } from "react";
import CoursesListSeo from "@/SEO/CoursesList";
import CoursesPageView from "./CoursesPage.view";
import useAuthActions from "@/store/actions/authAction";
import PageBuilder, {
  getPageBuilderProps,
} from "@/mvcComponents/CommonUI/Sections/PageBuilder.controller";
import { isMobile } from "@/hooks/useMobileBreakPoint";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import CoursesPageManager from "@/Logic/ClientLogic/Services/PageServices/CoursesPageManager";

const CoursesPageController = ({ pageData, customPageData }) => {
  ClientAppManager.instance.setPageManager(new CoursesPageManager());
  const router = useRouter();
  const { auth } = useAuthActions();

  const { seo = {}, faqs = [] } = pageData || {};
  const { coursesList = [] } = customPageData || {};

  useEffect(() => {
    const scrollToSection = () => {
      const hashes = router.asPath.match(/#([a-z0-9-]+)/gi);
      let hash =
        hashes && hashes.length
          ? hashes[0]?.replace("#", "")?.toLocaleLowerCase()
          : null;

      if (hash) {
        setTimeout(() => {
          let element = document.getElementById(hash);
          if (!element) {
            element = document.getElementById(`assessment-${hash}`);
          }
          if (element) {
            const offset = isMobile ? -40 : -150;
            const yCoordinate =
              element.getBoundingClientRect().top + window.scrollY + offset;
            // window.scrollTo({ top: yCoordinate, behavior: "smooth" });

            setTimeout(() => {
              element.classList.add("isHighlighted");
            }, 300);
          }
        }, 500);
      }
    };

    scrollToSection();
  }, [router.asPath]);

  const components = [
    {
      id: "CoursesListSeo",
      comp: CoursesListSeo,
      props: {
        metaData: seo,
        data: { coursesList, faqs },
      },
    },
    {
      id: "PageBuilder",
      comp: PageBuilder,
      props: getPageBuilderProps(pageData),
    },
  ];

  return <CoursesPageView components={components} isLoggedIn={auth?.token} />;
};

export default CoursesPageController;
