import { LOGIN_POPUP } from "@/store/actions/popupAction";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import { LoginPopupActionType } from "../FlowActionControllers.types";
import BasePopupActionController from "./BasePopupAction.controller";
import ClientAppManager from "../../../ClientAppManager";

class LoginPopupActionController<
  T extends BaseFlowController
> extends BasePopupActionController<T> {
  private actionData: LoginPopupActionType;
  constructor(flow: T, actionData: LoginPopupActionType) {
    super(flow);
    this.actionData = actionData;
  }

  protected checkAction(): boolean {
    if (ClientAppManager.instance.userDetailsManager?.user?.userDetails?._id) {
      return false;
    }

    return true;
  }

  protected async takeAction(): Promise<void> {
    this.openPopup(LOGIN_POPUP, {
      ...this.actionData,
      onSubmit: () => {
        this.actionCallBack();
      },
      onCloseByUser: () => {
        ClientAppManager.instance.userFlowManager.endFlow();
      },
    });
  }
}

export default LoginPopupActionController;
