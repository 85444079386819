import { NextRouter } from "next/router";
import BaseFlowController from "./FlowControllers/Baseflow.controller";
import Ftue1SubmitFlowController from "./FlowControllers/Ftue1SubmitFlowController";
import JoinWaitlistFlowController from "./FlowControllers/JoinWaitlistFlow.controller";
import ReturningWaitlistedUserFlowController from "./FlowControllers/ReturningWaitlistedUserFlow.controller";
import { FLOWTYPES, FlowDataType } from "./UserFlowService.types";
import CourseLeadExistingUserFlowFlowController from "./FlowControllers/CourseLeadExistingUserFlow.controller";
import CourseLeadNewUserFlowController from "./FlowControllers/CourseLeadNewUserFlow.controller";

class UserFlowManager {
  public currentFlow: BaseFlowController;
  public router: NextRouter;
  constructor() {}

  private checkAndStartFlow() {
    const startFlow = this.currentFlow.isFlowRequired();

    if (!startFlow) {
      this.endFlow();
      return;
    }
    this.currentFlow.preFlowStartSetup();
    this.currentFlow.takeAction();
  }

  private initialiseFlow(
    flow: new (flowData: FlowDataType) => BaseFlowController,
    flowData: FlowDataType
  ) {
    this.currentFlow = new flow(flowData);
  }

  private getFlowController(flowName: FLOWTYPES): new () => BaseFlowController {
    let flow = null;

    if (flowName == FLOWTYPES.JOIN_WAITLIST) {
      flow = JoinWaitlistFlowController;
    } else if (flowName == FLOWTYPES.RETURNING_JW_USER) {
      flow = ReturningWaitlistedUserFlowController;
    } else if (flowName == FLOWTYPES.FTUE1_SUBMIT) {
      flow = Ftue1SubmitFlowController;
    } else if (flowName == FLOWTYPES.COURSE_LEAD_EXISTING_USER) {
      flow = CourseLeadExistingUserFlowFlowController;
    } else if (flowName == FLOWTYPES.COURSE_LEAD_NEW_USER) {
      flow = CourseLeadNewUserFlowController;
    }

    return flow;
  }

  public checkAndTriggerFlow(
    flowName: FLOWTYPES,
    flowData?: FlowDataType
  ): void {
    let flow = this.getFlowController(flowName);

    if (flow) {
      sessionStorage.setItem("existing-flow", flowName.toString());
      if (flowData) {
        sessionStorage.setItem("flow-data", JSON.stringify(flowData));
      }
      this.initialiseFlow(flow, flowData);
      this.checkAndStartFlow();
    }
  }

  public initialiseExistingFlow(flowName: FLOWTYPES, flowData: FlowDataType) {
    let flow = this.getFlowController(flowName);

    if (flow) {
      this.initialiseFlow(flow, flowData);
    }
  }

  public endFlow(): void {
    sessionStorage.removeItem("existing-flow");
    sessionStorage.removeItem("flow-data");
    sessionStorage.removeItem("nextPotentialAction");
    this.currentFlow = null;
  }
}

export default UserFlowManager;
