import { NetworkManager } from "@/outscal-commons-frontend";

class AssessmentsManager {
  public async submitPendingAssessment(): Promise<void> {
    try {
      const assessmentId = localStorage.getItem("assessmentId");
      const assessmentQuestionResponses = JSON.parse(
        localStorage.getItem("assessmentQuestionResponses") || "[]"
      );

      if (!assessmentId || !assessmentQuestionResponses?.length) {
        // console.log("No pending assessments found in local storage.");
        return;
      }

      await NetworkManager.post(
        `/assessment-question-response/createAssessmentQuestionResponses/${assessmentId}`,
        { assessmentQuestionResponses }
      );

      localStorage.removeItem("assessmentId");
      localStorage.removeItem("assessmentQuestionResponse");
    } catch (error) {
      console.error("Error submitting pending assessment:", error);
    }
  }
}

export default AssessmentsManager;
