import BookCallPageActionController from "../FlowActionControllers/PageActionController/BookCallPageAction.controller";
import DiscordPageActionController from "../FlowActionControllers/PageActionController/DiscordPageAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import ReturnOriginPageActionController from "../FlowActionControllers/PageActionController/ReturnOriginPageAction.controller";
import WaitlistConfirmationPageActionController from "../FlowActionControllers/PageActionController/WaitistConfirmationPageAction.controller";
import GuestLoginPopupActionController from "../FlowActionControllers/PopupActionController/LoginPopupAction.controller";
import BaseFlowController from "./Baseflow.controller";

class CourseLeadNewUserFlowController extends BaseFlowController {
  protected actions = [
    new NameFormPageActionController<CourseLeadNewUserFlowController>(this),
    new PhoneNumberFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new ExperienceFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new GamingExperienceFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new PreferredJobCategoryFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new FresherJoinReasonFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new DiscordPageActionController<CourseLeadNewUserFlowController>(this),
    new BookCallPageActionController<CourseLeadNewUserFlowController>(this),
    new WaitlistConfirmationPageActionController<CourseLeadNewUserFlowController>(
      this
    ),
    new ReturnOriginPageActionController<CourseLeadNewUserFlowController>(this),
    new GuestLoginPopupActionController<CourseLeadNewUserFlowController>(this, {
      heading: "Get Started",
      subHeading:
        "Hey there! Ready to join us? Create your account now in no time!",
    }),
  ];

  public preFlowStartSetup(): void {
    localStorage.setItem("prev-url", "/courses");
  }
}

export default CourseLeadNewUserFlowController;
