import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Link from "next/link";
import React from "react";

const ConditionLinkView = ({
  children,
  link,
  rel = "follow",
  analytics = "link",
  section_id,
  legacy = false,
}) => {
  return (
    <>
      {link ? (
        !legacy ? (
          <Link
            href={link}
            rel={rel}
            data-analytics={[UIElements.LINK, `${section_id}-${analytics}`]}
            view-analytics={[UIElements.LINK, `${section_id}-${analytics}`]}
          >
            {children}
          </Link>
        ) : (
          <a
            href={link}
            rel={rel}
            data-analytics={[UIElements.LINK, `${section_id}-${analytics}`]}
            view-analytics={[UIElements.LINK, `${section_id}-${analytics}`]}
          >
            {children}
          </a>
        )
      ) : (
        children
      )}
    </>
  );
};

export default ConditionLinkView;
