import React, { useState } from "react";
import {
  AnalyticsManager,
  NetworkManager,
} from "@/outscal-commons-frontend/Managers";
import { toast } from "react-toastify";
import useAuthActions from "@/store/actions/authAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import usePopupActions, { LOGIN_POPUP } from "@/store/actions/popupAction";
import { RegisterPopupFormData, defaultData } from "./ProRegisterPopup.model";
import ProRegisterPopupView from "./ProRegisterPopup.view";
import ClientBotTrackingService from "@/Logic/ClientLogic/Services/AnalyticsUtils/ClientBotTrackingService";

const ProRegisterPopup = ({
  actions,
  showFields = false,
  heading = defaultData.heading,
  features = defaultData.features,
  subHeading = defaultData.subHeading,
  showLoginCta = true,
  dismissable = false,
  dismissOnOutsideClick = true,
  maxWidth = defaultData.maxWidth,
  children,
  socialAuthButtonText = defaultData.socialAuthButtonText,
  analyticsIntentText = "",
  registerFrom,
  metaText = "",
  showFeaturesInitially = true,
}) => {
  const { setToken } = useAuthActions();
  const { getAndSetUserProfile } = useUserActions();
  const { hidePopups, showPopup } = usePopupActions();

  const disableSocialAuth =
    (ClientBotTrackingService?.instance?.deviceInformation?.userAgent
      ?.toLowerCase()
      ?.includes("macintosh") ||
      ClientBotTrackingService?.instance?.deviceInformation?.userAgent
        ?.toLowerCase()
        ?.includes("iphone") ||
      ClientBotTrackingService?.instance?.deviceInformation?.userAgent
        ?.toLowerCase()
        ?.includes("ipad")) &&
    ClientBotTrackingService?.instance?.deviceInformation?.userAgent
      ?.toLowerCase()
      ?.includes("linkedinapp");

  const [toggleToEmailRegister, setToggleToEmailRegister] = useState(
    showFields || disableSocialAuth
  );

  const onRegister = async (user) => {
    try {
      const utm = UTMManager.instance.lastUtm;
      const landingIntent = UTMManager.instance.landingIntent;
      let intent = UTMManager.instance.getCurrentIntent();
      intent = {
        ...intent,
        registerFrom,
      };
      const retargetingIntent = UTMManager.instance.sessionIntent;
      let data = {
        ...user,
        actions,
        ...utm,
        intent: { landingIntent, intent, retargetingIntent },
      };
      let response = await NetworkManager.post(
        "/auth/signup",
        data,
        "",
        undefined,
        true
      );
      if (!response.status) {
        toast.error(
          response?.error && typeof response.error === "string"
            ? response.error
            : "Something went wrong."
        );
      } else {
        await setToken(response.token);
        await getAndSetUserProfile();
        hidePopups();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ShowLoginPopup = (e) => {
    AnalyticsManager.clickEvent(e);
    showPopup(LOGIN_POPUP, { showFields, disableSocialAuth });
  };

  return (
    <ProRegisterPopupView
      features={showFeaturesInitially ? features : []}
      showLoginCta={showLoginCta}
      children={children}
      maxWidth={maxWidth}
      hidePopups={hidePopups}
      showLoginPopup={ShowLoginPopup}
      registerPopupFormData={RegisterPopupFormData}
      onRegisterFunction={onRegister}
      toggleToEmailRegister={toggleToEmailRegister}
      setToggleToEmailRegister={setToggleToEmailRegister}
      heading={heading}
      analyticsIntentText={analyticsIntentText ? `_${analyticsIntentText}` : ""}
      subHeading={subHeading}
      dismissable={dismissable}
      dismissOnOutsideClick={dismissOnOutsideClick}
      socialAuthButtonText={socialAuthButtonText}
      metaText={metaText}
      registerFrom={registerFrom}
      disableSocialAuth={disableSocialAuth}
    />
  );
};

export default ProRegisterPopup;
