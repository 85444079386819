import React from "react";
import styles from "./QzRsltA.module.css";

const QzRsltAView = ({ heading, passingMarks, marksRange, defaultText }) => {
  let assessmentName;
  let score;
  let desc1 = defaultText;
  let desc2 = "";
  if (typeof sessionStorage != "undefined") {
    let userAssessment = JSON.parse(
      sessionStorage.getItem("user-assessment") || "{}"
    );
    assessmentName = userAssessment.assessmentName;
    score = userAssessment.score;
  }

  let textColor = "red";

  if (score >= passingMarks) {
    textColor = "green";
  }

  const ranges = Object.keys(marksRange || {});

  for (let range of ranges) {
    let numbers = range.split("-");
    if (score >= numbers[0] && score <= numbers[1]) {
      desc1 = marksRange[range].desc1;
      desc2 = marksRange[range].desc2;
    }
  }

  return (
    <div>
      <h1 className={styles.heading}>
        {heading || `Assessment : ${assessmentName}`}
      </h1>
      {score >= 0 && (
        <p className={styles.score}>Your Score : {score.toFixed(0)}/100</p>
      )}
      <p className={styles.desc} style={{ "--txt-color": textColor }}>
        {desc1}
      </p>
      <p className={styles.desc} style={{ "--txt-color": "#707074" }}>
        {desc2}
      </p>
    </div>
  );
};

export default QzRsltAView;
