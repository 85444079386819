export const Pages = {
  JOBS: "JOBS",
  JOB: "JOB",
  COURSES: "COURSES",
  COMPANY: "COMPANY",
  COMPANIES: "COMPANIES",
  COURSE: "COURSE",
  COURSE_MODULES: "COURSE_MODULES",
  PRACTICE: "PRACTICE",
  PROFILE: "PROFILE",
  PROFILE_POST: "PROFILE_POST",
  PROFILE_PROFILE_DETAILS: "PROFILE_PROFILE_DETAILS",
  STATIC_JOB_PAGE: "STATIC_JOB_PAGE",
  WELCOME: "WELCOME",
  LMS: "LMS",
  SUCCESS_STORIES: "SUCCESS_STORIES",
  VIDEOS: "VIDEOS",
  LANDINGPAGE: "LANDINGPAGE",
  DAILY_QUESTION: "DAILY_QUESTION",
  DAILY_ALL_QUESTIONS: "DAILY_ALL_QUESTIONS",
  PRACTICE_PAGE: "PRACTICE_PAGE",
  ASSESSMENT_PAGE: "ASSESSMENT_PAGE",
  JOB_APPLICATIONS: "JOB_APPLICATIONS",
  INVITE: "INVITE",
  BOOKINGS_PAGE: "BOOKINGS_PAGE",
  BOOKING_DETAILS: "BOOKING_DETAILS",
  ADS: "ADS",
  MCQS: "MCQS",
  LAYOFF_SCHOLARSHIP: "LAYOFF_SCHOLARSHIP",
  ONLINE_COMPILERS: "ONLINE_COMPILERS",
  SCHOLARSHIPS: "SCHOLARSHIPS",
  SCHOLARSHIP: "SCHOLARSHIP",
  ACTIONPLAN: "ACTION_PLAN",
  FORMPAGE: "FORM_PAGE",
  COMMUNITY: "COMMUNITY",
  BLOG: "BLOG",
  DISCORD: "DISCORD",
  SOCIAL_PAGE: "SOCIAL_PAGE",
  WAITLIST_CONFIRMATION: "WAITLIST_CONFIRMATION",
  COURSE_BOOK_CALL: "COURSE_BOOK_CALL",
};

export const UIElements = {
  BUTTON: "BUTTON",
  TEXT: "TEXT",
  INPUT: "INPUT",
  CARD: "CARD",
  LINK: "LINK",
  ICON: "ICON",
  TAB: "TAB",
  PLUG: "PLUG",
};

export const CommonElements = {
  HEADER: "HEADER",
  FOOTER: "FOOTER",
  POPUP: "POPUP",
  MOBILE_NAV: "MOBILE_NAV",
};

export const Actions = {
  open: "open",
  close: "close",
  pageVisit: "page_visit",
  elementView: "element_view",
};

export const CET_NAME = {};
