export enum FLOWTYPES {
  JOIN_WAITLIST,
  RETURNING_JW_USER,
  RETURNING_JW_USER_COURSE,
  FTUE1_SUBMIT,
  COURSE_LEAD_EXISTING_USER,
  COURSE_LEAD_NEW_USER,
}

export type FlowDataType = {
  waitlistedCourses?: string[];
};
