export const PhoneNumberActionData = {
  inputHeading:
    "Get your profile reviewed with in 2 days by providing phone number ",
};

export const BookACallActionData = {
  analyticsText: "book-call",
  ctaText: "Talk to an advisor",
  dismissable: true,
  image: "https://outscal-assets.s3.ap-south-1.amazonaws.com/phone-icon.png",
  imageSize: 130,
  maxWidth: "500px",
  message: "We will discuss your goals, and how we can help you achieve them",
  title: "Outscal Course Application",
  width: "80%",
  messageColor: "textDarkerGrey",
  messageFontSize: "xxl",
};

export const AskFillWaitlistFormData = {
  analyticsText: "fill-waitlist-form",
  ctaText: "Continue",
  dismissable: true,
  image: "https://outscal-assets.s3.ap-south-1.amazonaws.com/complete-form.jpg",
  imageSize: 130,
  maxWidth: "500px",
  message: "Please complete your application for game programming course",
  title: "Application Incomplete",
  width: "80%",
  messageColor: "textDarkerGrey",
  messageFontSize: "xl",
  padding: "20px 30px",
};

export const CourseLeadsAskLoginPopup = {
  heading: "Account Exists!",
  subHeading:
    "Hey Looks like you already have an account with us , please login for further steps",
};
