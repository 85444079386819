import { Div, Text } from "../../../Styled";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BiChevronDown } from "react-icons/bi";
import CountriesDropDown from "./CountriesDropDown";
import { countriesObj } from "../../../utils/country";

const Root = styled(Div)`
  width: 10%;
  min-width: 70px;
  display: flex;
  ${(p) => ` background-color:${p.theme.colors.white}`};
  ${(p) => `border-right:1px solid rgb(0, 0, 0, 0.23)`};
  ${(p) =>
    `border-radius: ${p.theme.borderRadius.xxxs} 0px 0px ${p.theme.borderRadius.xxxs}`};
`;

const CountrySelect = styled(Div)`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px;
`;

const CountrySelectLeftHelper = ({ value, onChange, helperText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const openDropDown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const selectLeftHelperRef = useRef(null);

  useEffect(() => {
    const handleOuterClick = (event) => {
      if (
        selectLeftHelperRef.current &&
        !selectLeftHelperRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOuterClick);

    return () => {
      document.removeEventListener("click", handleOuterClick);
    };
  }, []);
  return (
    <Root ref={selectLeftHelperRef}>
      <CountrySelect onClick={() => openDropDown()}>
        <Text fontSize="md">{helperText}</Text>
        <BiChevronDown />
      </CountrySelect>
      {isDropdownOpen && (
        <CountriesDropDown
          optionsList={countriesObj}
          selected={value}
          onSelected={(countryCode, countryObj) => {
            onChange(countryCode, countryObj);
            setIsDropdownOpen(false);
          }}
        />
      )}
    </Root>
  );
};

export default CountrySelectLeftHelper;
