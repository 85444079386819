import React from "react";
import { useRouter } from "next/router";
import IconTextCardView from "./IconTextCard.view";

const IconTextCard = ({ item, customCardData, name }) => {
  const router = useRouter();
  let { action = null } = item || {};
  const {
    disableLinkClick = false,
    target = "",
    primaryColor = "themeBlue",
    secondaryColor = "lightBlue",
    highlightColor = "themeYellow",
    borderRadius = "xs",
    padding = "16px",
    variant,
    iconSize = "5xl",
  } = customCardData || {};

  const disableClick = (e) => {
    e.preventDefault();
  };

  const onClick = (e, link) => {
    e.preventDefault();
    target == "" ? router.push(link) : window.open(link);
  };

  return (
    <IconTextCardView
      item={item}
      customCardData={customCardData}
      cursor={
        disableLinkClick
          ? "initial"
          : item?.link || action
          ? "pointer"
          : "initial"
      }
      onClick={
        action
          ? action
          : disableLinkClick
          ? disableClick
          : item?.link
          ? onClick
          : disableClick
      }
      analyticsText={name}
    />
  );
};

export default IconTextCard;
