import React, { useState, useEffect } from "react";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import { toast } from "react-toastify";
import {
  CoursesLeadsFormData as coursesLeadsFormData,
  defaultPhoneNumberData,
  formFilledData,
  fsgdCourseDetails,
} from "./CourseLeadsForm.model";
import CourseLeadsFormView from "./CourseLeadsForm.view";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import FormFilledView from "./FormFilledView";
import useAuthActions from "@/store/actions/authAction";
import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { CREATE_COURSE_LEAD } from "@/Logic/GraphQL/Mutations/courseLeads.mutation";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import { FLOWTYPES } from "@/Logic/ClientLogic/Services/UserFlowService/UserFlowService.types";

const CourseLeadsForm = ({
  paidCoursesIdAndLabels = [],
  formGap,
  dropdownHeading = "Course you are interested in*",
  ctaText = "Connect With Us",
  formHeading = "Reach out to us",
  showGoogleAuthCta = true,
}) => {
  const { user, getAndSetLogoutUserProfile } = useUserActions();
  const [defaultValues, setDefaultValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { auth } = useAuthActions();

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setSubmitted(localStorage.getItem("courseLeadSubmitted") === "true");
    }
  }, []);

  const populateFormData = async () => {
    let defaultObj = {};
    defaultObj.name = user?.userJobProfile?.name;
    defaultObj.email = user?.userDetails?.email;
    defaultObj.phone_number = defaultPhoneNumberData;
    defaultObj.phone_number.number = user?.userJobProfile?.phone_number;
    defaultObj.course_joining_reason =
      user?.userJobProfile?.user_form_data?.course_joining_reason;
    setDefaultValues(defaultObj);
  };

  useEffect(() => {
    populateFormData();
  }, [user]);

  const onSubmit = async (e) => {
    const resp = await ApolloClientManager.client.mutate({
      mutation: CREATE_COURSE_LEAD,
      variables: {
        name: e.name,
        email: e.email,
        phone_number: e.phone_number,
        courseJoiningReason: e.courseJoiningReason?.id || "",
        utms: UTMManager.instance.lastUtm,
      },
    });

    localStorage.setItem("courseLeadSubmitted", true);
    sessionStorage.setItem("course-lead-email", e.email);

    if (!auth?.token && !resp?.data?.createCourseLead?.user?._id) {
      sessionStorage.setItem(
        "logout-user-profile",
        JSON.stringify({
          email: e.email,
          profile: {
            name: e.name,
            phone_number: e.phone_number?.number,
            country: e.phone_number?.country_code,
            calling_code: e.phone_number?.calling_code,
            user_form_data: {
              course_joining_reason: e.courseJoiningReason?.id,
            },
          },
        })
      );
      getAndSetLogoutUserProfile();
      ClientAppManager.instance.userFlowManager.checkAndTriggerFlow(
        FLOWTYPES.COURSE_LEAD_NEW_USER
      );
    } else {
      ClientAppManager.instance.userFlowManager.checkAndTriggerFlow(
        FLOWTYPES.COURSE_LEAD_EXISTING_USER
      );
    }
  };

  const updateCoursesLeadsFormData = (paidCoursesIdAndLabels) => {
    const updatedFormData = { ...coursesLeadsFormData };

    if (paidCoursesIdAndLabels.length < 1) {
      const { course_of_interest, ...remainingInputs } = updatedFormData.inputs;
      updatedFormData.inputs = remainingInputs;
    }

    return updatedFormData;
  };

  return !submitted ? (
    <CourseLeadsFormView
      formName={coursesLeadsFormData?.name}
      formHeading={formHeading}
      formData={updateCoursesLeadsFormData(paidCoursesIdAndLabels)?.inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitText={ctaText}
      hasAuthToken={auth?.token}
      paidCoursesIdAndLabels={paidCoursesIdAndLabels}
      formGap={formGap}
      showGoogleAuthCta={showGoogleAuthCta}
    />
  ) : (
    <FormFilledView
      heading={formFilledData?.heading}
      subHeading={formFilledData?.subHeading}
      ctaText={formFilledData?.ctaText}
      ctaLink={formFilledData?.ctaLink}
    />
  );
};

export default CourseLeadsForm;
