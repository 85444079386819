import ClientAppManagerInterface from "./ClientAppManagerInterface";
import { UserType } from "./Service.types";

class UserDetailsManager {
  public user: UserType;
  private isInitialised: boolean = false;
  private clientAppManager: ClientAppManagerInterface;
  constructor(t: ClientAppManagerInterface) {
    this.clientAppManager = t;
  }

  private onInitialise = () => {
    this.clientAppManager.checkTriggerFlowOnDetailsInitialised();
  };

  public initialise(): void {
    if (!this.isInitialised) {
      this.isInitialised = true;
      this.onInitialise();
    }
  }

  public setUserData(user: UserType): void {
    this.user = { ...this.user, ...user };
  }

  public async setTokenCallback(token: String): Promise<void> {
    try {
      if (token) {
        await this.clientAppManager.assessmentsManager.submitPendingAssessment();
        await this.clientAppManager.jobAlertsManager.createPostAuthAlert();
      } else {
        console.error("No auth token available to execute the callback.");
      }
    } catch (error) {
      console.error("Error executing token callback action:", error);
    }
  }
}

export default UserDetailsManager;
