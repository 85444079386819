import BookCallPageActionController from "../FlowActionControllers/PageActionController/BookCallPageAction.controller";
import DiscordPageActionController from "../FlowActionControllers/PageActionController/DiscordPageAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import ReturnOriginPageActionController from "../FlowActionControllers/PageActionController/ReturnOriginPageAction.controller";
import WaitlistConfirmationPageActionController from "../FlowActionControllers/PageActionController/WaitistConfirmationPageAction.controller";
import LoginPopupActionController from "../FlowActionControllers/PopupActionController/LoginPopupAction.controller";
import BaseFlowController from "./Baseflow.controller";

class CourseLeadExistingUserFlowFlowController extends BaseFlowController {
  protected actions = [
    new LoginPopupActionController<CourseLeadExistingUserFlowFlowController>(
      this,
      {
        heading: "Account Already Exists",
        subHeading:
          "Hey there! You’re already a part of our community. Log in to continue your journey",
      }
    ),
    new NameFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new PhoneNumberFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new ExperienceFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new GamingExperienceFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new PreferredJobCategoryFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new FresherJoinReasonFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new DiscordPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new BookCallPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new WaitlistConfirmationPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
    new ReturnOriginPageActionController<CourseLeadExistingUserFlowFlowController>(
      this
    ),
  ];

  public preFlowStartSetup(): void {
    localStorage.setItem("prev-url", "/courses");
  }
}

export default CourseLeadExistingUserFlowFlowController;
