import BaseFlowController from "../FlowControllers/Baseflow.controller";

class BaseActionController<T extends BaseFlowController> {
  protected myFlow: T;
  constructor(flow: T) {
    this.myFlow = flow;
  }

  public async checkAndTakeAction(): Promise<boolean> {
    if (this.checkAction()) {
      await this.takeAction();
      return true;
    }
    return false;
  }

  protected checkAction(): boolean {
    return true;
  }

  protected async takeAction(): Promise<void> {}

  protected actionCallBack(): void {
    this.myFlow.takeAction();
  }
}

export default BaseActionController;
