import React, { useRef } from "react";
import styled from "styled-components";
import { Div } from "../../Styled";
import CountrySelectLeftHelper from "./CountrySelect/CountrySelectLeftHelper";
import InputHeading from "./InputHeading";
import { ErrorText, Root } from "./DefaultInputStyle";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";

const CountrySelectAndInputRoot = styled(Div)`
  display: flex;
  position: relative;
  width: 100%;
  ${(p) => `border:1px solid rgb(0, 0, 0, 0.23)`};
  ${(p) => `border-radius: ${p.theme.borderRadius.xxxs}`};
  &:hover {
    ${(p) => `border:1px solid ${p.theme.colors.themeYellow}`};
  }
  overflow: "hidden";
`;
const NumberRoot = styled.input`
  display: flex;
  ${(p) => `border-radius: ${p.theme.borderRadius.xxxs}`};
  width: 90%;
  border: none !important;
  padding: 10px 9px;
  font-size: 16px;
`;

const IconWrapper = styled(Div)`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  ${(p) => `color:${p.theme.colors.textDarkerGrey}`};
`;
const CurrencyInput = ({
  heading,
  value,
  name,
  onChange,
  error,
  inputStyles,
  isVisible,
}) => {
  const { gap, direction } = inputStyles;

  const onCountrySelect = (countryCode, countryObj) => {
    onChange({
      target: {
        name,
        value: {
          country_code: countryCode,
          currency_code: countryObj.currency_code,
          compensation: value?.compensation,
        },
      },
    });
  };

  return (
    <>
      <Root gap={gap} direction={direction} isVisible={isVisible}>
        {heading && <InputHeading heading={heading} error={error} />}
        <CountrySelectAndInputRoot>
          <CountrySelectLeftHelper
            value={value}
            onChange={onCountrySelect}
            helperText={value?.currency_code}
          />
          <NumberRoot
            placeholder="6"
            name={name}
            type="number"
            data-analytics={[UIElements.INPUT, "Compensation"]}
            value={value?.compensation}
            onChange={(e) => {
              onChange({
                target: {
                  name,
                  value: {
                    country_code: value.country_code,
                    currency_code: value.currency_code,
                    compensation: e.target.value,
                  },
                },
              });
            }}
            onWheel={(e) => e.target.blur()}
          ></NumberRoot>
          <IconWrapper>LPA</IconWrapper>
        </CountrySelectAndInputRoot>
        {error && (
          <ErrorText>
            {typeof error === "object"
              ? Object.keys(error).map((err) => <>{error[err]}</>)
              : error}
          </ErrorText>
        )}
      </Root>
    </>
  );
};

export default CurrencyInput;
