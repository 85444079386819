import { MESSAGE_POPUP } from "@/store/actions/popupAction";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import { AskFillWaitlistFormActionType } from "../FlowActionControllers.types";
import ClientAppManager from "../../../ClientAppManager";
import BasePopupActionController from "./BasePopupAction.controller";

class AskFillWaitlistFormPopupActionController<
  T extends BaseFlowController
> extends BasePopupActionController<T> {
  private actionData: AskFillWaitlistFormActionType;
  constructor(flow: T, actionData: AskFillWaitlistFormActionType) {
    super(flow);
    this.actionData = actionData;
  }

  public async takeAction(): Promise<void> {
    localStorage.setItem(
      "askFillWaitlistFormOpened",
      JSON.stringify(new Date())
    );
    this.openPopup(MESSAGE_POPUP, {
      ...this.actionData,
      action: () => {
        this.actionCallBack();
      },
      onCloseByUser: () => {
        ClientAppManager.instance.userFlowManager.endFlow();
      },
    });
  }
}

export default AskFillWaitlistFormPopupActionController;
