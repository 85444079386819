class LocalStorageManager {
  private isAvailable: boolean;

  constructor() {
    this.isAvailable = typeof localStorage !== "undefined";
    if (!this.isAvailable) {
      console.warn("localStorage is not available in this environment.");
    }
  }

  private ensureAvailable(): boolean {
    if (!this.isAvailable) {
      console.warn("localStorage is not available.");
      return false;
    }
    return true;
  }

  get(key: string): string | null {
    if (!this.ensureAvailable()) return null;
    return localStorage.getItem(key);
  }

  set(key: string, value: string): void {
    if (!this.ensureAvailable()) return;
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    if (!this.ensureAvailable()) return;
    localStorage.removeItem(key);
  }

  clear(): void {
    if (!this.ensureAvailable()) return;
    localStorage.clear();
  }

  get length(): number {
    return this.isAvailable ? localStorage.length : 0;
  }
}

export default new LocalStorageManager();
