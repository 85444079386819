import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class DiscordPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/social/course-join-discord";

  protected override async takeAction(): Promise<void> {
    if (this.myFlow.flowData?.waitlistedCourses?.length) {
      sessionStorage.setItem(
        "discord-action",
        JSON.stringify({
          name: "course-waitlist",
          payload: this.myFlow.flowData?.waitlistedCourses,
        })
      );
    }
    super.takeAction();
  }

  public override checkAction(): boolean {
    return !ClientAppManager.instance.userDetailsManager?.user?.userDetails
      ?.discord?.id
      ? true
      : false;
  }
}

export default DiscordPageActionController;
