import styled, { css } from "styled-components";
import { Div } from "@/outscal-commons-frontend/Styled";
import Constant from "@/staticData/constant";

export const FiltersDiv = styled(Div)`
  display: flex;
  position: sticky;
  ${(p) => p.isIphone && `flex-wrap:wrap`};
  top: ${Constant.NAVBAR_HEIGHT};
  background-color: ${(p) => p.theme.colors.white};
  z-index: 5;
  padding: 16px 0 5px 0;
  width: 100%;
  gap: 10px;
  transition: all 0.4s linear;
  @media (max-width: 1000px) {
    overflow-x: scroll;
    border-bottom: 1px solid ${(p) => p.theme.colors.borderGray};
    margin-bottom: 10px;
    &::-webkit-scrollbar {
      height: 7px;
      display: flex;
    }
    &::-webkit-scrollbar-track {
      background: ${(p) => p.theme.colors.white};
    }
    &::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.colors.borderGray};
      border-radius: 4px;
    }
  }
  @media screen and (max-width: 500px) {
    top: 0px;
    margin-bottom: 0;

    ${(p) =>
      p.hideHeader
        ? css`
            transform: translateY(-100%);
            opacity: 0;
            height: 0;
            padding: 0;
          `
        : css`
            opacity: 1;
            height: 100%;
            padding: 12px 0 8px 0;
          `};
  }
`;

export const ClearButton = styled.button`
  ${(p) =>
    `background:${
      p.theme.colors[p.disableFiltersClear ? "iconBgGrey" : "white"]
    }`};
  ${(p) =>
    `color:${
      p.theme.colors[p.disableFiltersClear ? "textDarkGrey" : "black"]
    }`};
  ${(p) => `border:1px solid ${p.theme.colors.borderGray}`};
  ${(p) => `font-size:${p.theme.fontSize.sm}`};
  ${(p) => `border-radius:${p.theme.borderRadius.xxs}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
  padding: 8px 22px;
  &:hover {
    cursor: ${(p) => (p.disableFiltersClear ? "inherit" : "pointer")};
    ${(p) => !p.disableFiltersClear && `background:${p.theme.colors.black}`};
    ${(p) => !p.disableFiltersClear && `color:${p.theme.colors.white}`};
  }
`;
