import ShowCongratsPopupActionController from "../FlowActionControllers/ShowCongratsPopupAction.controller";
import { FlowDataType } from "../UserFlowService.types";
import BaseFlowController from "./Baseflow.controller";

class Ftue1SubmitFlowController extends BaseFlowController {
  protected actions = [
    new ShowCongratsPopupActionController<Ftue1SubmitFlowController>(this, {}),
  ];

  constructor(flowData: FlowDataType) {
    super(flowData);
  }
}

export default Ftue1SubmitFlowController;
