import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class ReturnOriginPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url =
    localStorage.getItem("prev-url") || "/course/full-stack-game-development";

  constructor(flow: T) {
    super(flow);
  }

  protected async takeAction(): Promise<void> {
    await super.takeAction();
    this.actionCallBack();
  }
}

export default ReturnOriginPageActionController;
