export const courseListingMap = {
  Specialization: { styleClass: "specialization" },
  Assessment: { styleClass: "assessment" },
  Scholarship: { styleClass: "scholarship" },
  Project: { styleClass: "project" },
  Course: { styleClass: "course" },
};

export const difficultyColorsMap = {
  Beginner: {
    primaryColor: "textGreen",
    secondaryColor: "lightBgGreen",
    icon: "MdOutlineSignalCellularAlt2Bar",
  },
  Intermediate: {
    primaryColor: "darkMud",
    secondaryColor: "seoLightBgYellow",
    icon: "MdOutlineSignalCellularAlt",
  },
  Advanced: {
    primaryColor: "deepRed",
    secondaryColor: "bgLightRed",
    icon: "FaSignal",
  },
};

export const defaultFailedAssessmentCta = {
  "goal": "Placement, Switch to Game Development",
  "slug": "full-stack-game-development",
  "image": {
    "url": "https://outscal-content.s3.ap-south-1.amazonaws.com/fullSize/full-stack-game-development.png"
  },
  "title": "Full-Stack Game Development",
  "rating": 4.9,
  "skills": {
    "data": [
      {
        "display_name": "C++"
      },
      {
        "display_name": "C#"
      },
      {
        "display_name": "Unity3D"
      },
      {
        "display_name": "DSA"
      }
    ]
  },
  "uspTag": "100% Placement Record",
  "feature": "Unlimited 1 on 1 mentorship",
  "tagline": "8-month flagship program from Outscal for you to unlock opportunities with top game studios.",
  "duration": "8 Months",
  "bannerText": "10% off",
  "buttonText": "Know more",
  "description": "1-on-1 mentorship program to harness your game development skills and unlock placement opportunities with top game studios",
  "enrolledUsers": "428 Users Enrolled",
  "difficultyLevel": "Intermediate/Advanced",
  "shortDescription": "Premium Program with Alumni working at top studios like Ubisoft, Zynga, etc."
}