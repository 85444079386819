import React from "react";
import MessagePopupView from "./MessagePopup.view";
import usePopupActions from "@/store/actions/popupAction";

const MessagePopup = ({
  title = "",
  message = "",
  action,
  ctaText = "",
  htmlMessage,
  messageColor = "black",
  messageFontSize = "xxl",
  width = "90%",
  maxWidth = "500px",
  padding = "20px 30px",
  image = "",
  imageSize = 80,
  dismissable,
  analyticsText,
  metaText = "",
  onPopupClose = () => {},
  onCloseByUser = () => {},
  cta2Text = "",
  cta2Action,
  customComponent,
}) => {
  const { hidePopups } = usePopupActions();

  return (
    <MessagePopupView
      customComponent={customComponent}
      hidePopups={hidePopups}
      title={title}
      message={message}
      action={action}
      ctaText={ctaText}
      htmlMessage={htmlMessage}
      messageColor={messageColor}
      messageFontSize={messageFontSize}
      width={width}
      maxWidth={maxWidth}
      padding={padding}
      image={image}
      imageSize={imageSize}
      dismissable={dismissable}
      analyticsText={analyticsText}
      metaText={metaText}
      onPopupClose={onPopupClose}
      onCloseByUser={onCloseByUser}
      cta2Text={cta2Text}
      cta2Action={cta2Action}
    />
  );
};

export default MessagePopup;
