import React from "react";
import RichTextView from "./RichText.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const RichText = ({
  heading,
  subHeading,
  content,
  showOn,
  section_id = "",
  headingAlignment = "justify",
  contentAlignment = "justify",
  codeLanguage = "cpp",
  ctaText = "",
  ctaLink = "",
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section
        data-outscal-section
        className="txt-content"
        id={section_id}
        view-analytics={[UIElements.CARD, `${section_id}-rich-text`]}
      >
        <RichTextView
          section_id={section_id}
          heading={heading}
          subHeading={subHeading}
          content={content}
          headingAlignment={headingAlignment}
          codeLanguage={codeLanguage}
          contentAlignment={contentAlignment}
          ctaText={ctaText}
          ctaLink={ctaLink}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default RichText;
