import React from "react";
import Image from "next/image";
import { CopyBlock } from "react-code-blocks";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./RichText.module.css";
import Link from "next/link";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const RichTextView = ({
  heading,
  subHeading,
  content,
  headingAlignment,
  contentAlignment,
  codeLanguage,
  ctaText,
  ctaLink,
  section_id,
}) => {
  return (
    <div
      className={styles["rich-text-root"]}
      view-analytics={[UIElements.CARD, `${section_id}-rich-text`]}
      style={{ textAlign: headingAlignment, alignItems: headingAlignment }}
    >
      {heading && (
        <h2 className={styles["title"]} style={{ textAlign: headingAlignment }}>
          {heading}
        </h2>
      )}
      {subHeading && (
        <div
          className={styles["sub-heading"]}
          style={{ textAlign: headingAlignment }}
        >
          {subHeading}
        </div>
      )}
      <BlocksRenderer
        content={content}
        blocks={{
          heading: ({ level, children }) => {
            if (level === 1) {
              return <h1 className={styles["h1"]}>{children}</h1>;
            }
            if (level === 2) {
              return <h2 className={styles["h2"]}>{children}</h2>;
            }
            if (level === 3) {
              return <h3 className={styles["h3"]}>{children}</h3>;
            }
          },
          image: ({ image }) => {
            return (
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.alternativeText || ""}
              />
            );
          },
          paragraph: ({ children }) => {
            return (
              <p
                className={styles["paragraph"]}
                style={{ textAlign: contentAlignment }}
              >
                {children}
              </p>
            );
          },
          list: ({ format, children }) => {
            if (format === "ordered") {
              return (
                <ol
                  className={styles["ol"]}
                  style={{ textAlign: contentAlignment }}
                >
                  {children}
                </ol>
              );
            } else {
              return (
                <ul
                  className={styles["ul"]}
                  style={{ textAlign: contentAlignment }}
                >
                  {children}
                </ul>
              );
            }
          },
          link: ({ children, url }) => (
            <Link
              className={styles["link"]}
              href={url}
              style={{ textAlign: contentAlignment }}
            >
              {children}
            </Link>
          ),
          code: ({ children }) => (
            <div className={styles["code"]}>
              <CopyBlock
                text={children[0]?.props?.text}
                language={codeLanguage}
                showLineNumbers={true}
                codeBlock={true}
                theme="obsidian"
              />
            </div>
          ),
        }}
      />
      {ctaLink && ctaText && (
        <ConditionLinkView
          link={ctaLink}
          analytics="rich-text"
          section_id={section_id}
        >
          <div className={styles.cta}>{ctaText}</div>
        </ConditionLinkView>
      )}
    </div>
  );
};

export default RichTextView;
