import PopupService from "../../../PopupService";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BaseActionController from "../BaseAction.controller";

class BasePopupActionController<
  T extends BaseFlowController
> extends BaseActionController<T> {
  protected async openPopup(popupName: number, popupData: {}) {
    PopupService.instance.showPopup(popupName, popupData);
  }
}

export default BasePopupActionController;
