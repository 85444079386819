import Link from "next/link";
import React from "react";
import {
  CardWrapper,
  StyledText,
  IconWrapper,
  SubText,
} from "./IconTextCard.styles";
import { RowDiv, ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";

const IconTextCardView = ({
  item,
  onClick,
  cursor,
  analyticsText,
  customCardData,
}) => {
  const {
    text = "",
    subText = "",
    link = "",
    // icon: Icon,
    icon,
  } = item || {};

  const {
    primaryColor = "themeBlue",
    secondaryColor = "lightBlue",
    highlightColor = "themeYellow",
    borderRadius = "xs",
    padding = "16px",
    variant,
    iconSize = "5xl",
    width = "",
    hoverScale = 0,
  } = customCardData || {};

  return (
    <CardWrapper
      hoverScale={hoverScale}
      width={width}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      highlightColor={highlightColor}
      borderRadius={borderRadius}
      padding={padding}
      isOutlined={variant == "outlined"}
      cursor={cursor}
      onClick={(e) => onClick(e, link)}
      data-analytics={[
        UIElements.LINK,
        `icon_button${analyticsText ? `_${analyticsText}_${text}` : ""}`,
      ]}
    >
      <RowDiv gap="12px" alignItems="center">
        {icon && (
          <IconWrapper primaryColor={primaryColor} iconSize={iconSize}>
            <DynamicIcon icon={icon} />
          </IconWrapper>
        )}
        <ColDiv>
          <ConditionLinkView link={link}>
            <StyledText primaryColor={primaryColor}>{text}</StyledText>
          </ConditionLinkView>
          {subText && <SubText>{subText}</SubText>}
        </ColDiv>
      </RowDiv>
    </CardWrapper>
  );
};

export default IconTextCardView;
