import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class WaitlistConfirmationPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/waitlist-confirmation";
}

export default WaitlistConfirmationPageActionController;
