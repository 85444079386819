import React, { useEffect, useState } from "react";
import CheckboxInput from "../CheckboxInput";
import styles from "./GroupedCategoryCheckbox.module.css";
import { FaChevronDown } from "react-icons/fa";

const CategoryView = ({
  name,
  label,
  subCategories,
  onChange,
  groupValue,
  openByDefault,
}) => {
  const subCategoryKeys = Object.keys(subCategories);
  const [subCategoriesVisible, setSubCategoriesVisible] = useState(false);

  const onCategoryToggle = (visible) => {
    const subCategoriesDiv = document.getElementById(`${name}-sub-categories`);
    const icon = document.getElementById(`${name}-rotate-icon`);
    if (visible) {
      subCategoriesDiv.style.height = `${subCategoriesDiv.scrollHeight}px`;
      icon.style.transform = `rotate(180deg)`;
    } else {
      icon.style.transform = `rotate(0deg)`;
      subCategoriesDiv.style.height = 0;
    }

    setSubCategoriesVisible(visible);
  };

  useEffect(() => {
    if (openByDefault) {
      const subCategoriesDiv = document.getElementById(
        `${name}-sub-categories`
      );
      const icon = document.getElementById(`${name}-rotate-icon`);
      setSubCategoriesVisible(true);
      subCategoriesDiv.style.height = subCategoriesDiv.scrollHeight + "px";
      icon.style.transform = `rotate(180deg)`;
    }
  }, []);

  return (
    <div className={styles["category-wrapper"]}>
      <div
        className={styles["category"]}
        onClick={() => {
          onCategoryToggle(!subCategoriesVisible);
        }}
      >
        <CheckboxInput
          label={label}
          name={name}
          onChange={onChange}
          value={groupValue && groupValue[name].value}
        />
        <div id={`${name}-rotate-icon`} className={styles["icon"]}>
          <FaChevronDown color={"grey"} />
        </div>
      </div>
      <div className={styles["sub-categories"]} id={`${name}-sub-categories`}>
        {subCategoryKeys.map((subCategory) => {
          const { label } = subCategories[subCategory];
          return (
            <div
              className={styles["sub-category"]}
              key={`${name}.${subCategory}`}
            >
              <CheckboxInput
                name={`${name}.${subCategory}`}
                label={label}
                onChange={onChange}
                value={
                  groupValue &&
                  groupValue[name].subCategories[subCategory].value
                }
                disabled={!groupValue[name].value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryView;
