import { Div } from "../../../Styled";
import React, { useRef } from "react";
import styled from "styled-components";

const OptionsContainer = styled(Div)`
  height: 300px;
  overflow-y: scroll;
  display: flex;
  background: white;
  flex-direction: column;
  gap: 5px;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(p) => p.theme.colors.grey};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(p) => p.theme.colors.textDarkGrey};
  }
`;
const Root = styled(Div)`
  ${(p) => `border:1px solid ${p.theme.colors.borderGray}`};
  position: absolute;
  top: 45px;
  width: 100%;
  height: auto;
  background: white;
  z-index: 50;
  padding: 5px;
  ${(p) => `border-radius:${p.theme.borderRadius.xxxs}`};
`;

const CountryOption = styled(Div)`
  cursor: pointer;
  ${(p) => `border-radius:${p.theme.borderRadius.xxs}`};
  padding: 5px;
  margin-right: 5px;
  ${(p) => p.bgColor && `background:${p.theme.colors[p.bgColor]}`};
  &:hover {
    ${(p) => `background:${p.theme.colors.highlightYellow}`};
  }
`;
const CountriesDropDown = ({ optionsList, selected, onSelected }) => {
  return (
    <Root>
      <OptionsContainer>
        {Object.keys(optionsList).map((countryCode) => {
          return (
            <CountryOption
              bgColor={
                selected?.country_code === countryCode ? "themeYellow" : null
              }
              ref={(ref) => {
                if (selected?.country_code === countryCode) {
                  ref?.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
              key={countryCode}
              onClick={() => {
                onSelected(countryCode, optionsList[countryCode]);
              }}
            >
              {optionsList[countryCode].label +
                " (" +
                optionsList[countryCode].calling_code +
                ", " +
                optionsList[countryCode].currency_code +
                ") "}
            </CountryOption>
          );
        })}
      </OptionsContainer>
    </Root>
  );
};

export default CountriesDropDown;
