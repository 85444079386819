export const TEXT = 1;
export const SELECT = 2;
export const MULTI_SELECT = 3;
export const TEXT_AREA = 4;
export const RADIO = 5;
export const DROPDOWN_SELECT = 6;
export const FILE_UPLOAD = 7;
export const DATE_TIME = 8;
export const MOBILE_NUMBER = 9;
export const CHECKBOX = 10;
export const PHONE_NUMBER = 11;
export const ANNUAL_COMPENSATION = 12;
export const GAME_BUILD_UPLOAD = 13;
export const HEADING = 14;
export const RATING = 14;
export const MULTI_SELECT_CHECKBOX = 17;
export const GROUPED_CATEGORY_CHECKBOX = 18;
// export const RICH_TEXT = 16;

export const AllInputTypes = [
  TEXT,
  SELECT,
  MULTI_SELECT,
  DROPDOWN_SELECT,
  RADIO,
  DATE_TIME,
  FILE_UPLOAD,
  MOBILE_NUMBER,
  TEXT_AREA,
  CHECKBOX,
  PHONE_NUMBER,
  ANNUAL_COMPENSATION,
  GAME_BUILD_UPLOAD,
  HEADING,
  RATING,
  MULTI_SELECT_CHECKBOX,
  GROUPED_CATEGORY_CHECKBOX,
  // RICH_TEXT
];
