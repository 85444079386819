import BaseFlowController from "../FlowControllers/Baseflow.controller";
import BaseActionController from "./BaseAction.controller";
import PopupService from "../../PopupService";
import { PRO_UNLOCKED_INFO_POPUP } from "@/store/actions/popupAction";

class ShowCongratsPopupActionController<
  T extends BaseFlowController
> extends BaseActionController<T> {
  private actionData: {};
  constructor(flow: T, actionData: {}) {
    super(flow);
    this.actionData = actionData;
  }

  override checkAction(): boolean {
    return true;
  }

  override async takeAction(): Promise<void> {
    PopupService.instance.showPopup(PRO_UNLOCKED_INFO_POPUP, {
      ...this.actionData,
      onClose: () => {
        this.actionCallBack();
      },
    });
  }
}

export default ShowCongratsPopupActionController;
