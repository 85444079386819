export const jobResources = {
  technical: [
    {
      link: "/course/full-stack-game-development",
      title: "Paid - Fullstack Game Development Bootcamp",
      mobileTitle: "Paid - Game Dev Bootcamp",
      id: "course-fsgd",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    {
      link: "/course/game-development-advance",
      title: "Paid - Game Programming with Programming Patterns",
      mobileTitle: "Paid - Game Programming Patterns",
      id: "course-game-development-advance",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    {
      link: "/scholarship/layoff",
      title: "Scholarship for Laid-Off Gaming Professionals",
      mobileTitle: "For Laid-Off Gaming Professionals",
      id: "scholarship-layoff",
      tags: ["Scholarship"],
      pillColor: "#cc0621",
    },
    {
      link: "/scholarship/student",
      title: "Scholarship for College Students",
      mobileTitle: "For College Students",
      id: "scholarship-student",
      tags: ["Scholarship"],
      pillColor: "#cc0621",
    },
    {
      link: "https://www.linkedin.com/in/abhisheketh/",
      title: "Follow Abhishek Smith for game programming content",
      mobileTitle: "Follow Abhishek - tech content",
      id: "abhishek-smith-linkedin",
      tags: ["Linkedin"],
    },
  ],

  nonTechnical: [
    {
      link: "/course/full-stack-game-development",
      title: "Paid - Fullstack Game Development Bootcamp",
      mobileTitle: "Paid - Game Dev Bootcamp",
      id: "course-fsgd",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    {
      link: "https://www.linkedin.com/in/mayankgrover/",
      title: "Follow Mayank Grover for game development resources",
      mobileTitle: "Follow Mayank - career advice",
      id: "mayank-grover-linkedin",
      tags: ["Linkedin"],
    },
    {
      link: "/scholarship/layoff",
      title: "Scholarship for Laid Off Gaming Professionals",
      mobileTitle: "For Laid-Off Gaming Professionals",
      id: "scholarship-layoff",
      tags: ["Scholarship"],
      pillColor: "#cc0621",
    },
    {
      link: "/scholarship/student",
      title: "Scholarship for College Students",
      mobileTitle: "For College Students",
      id: "scholarship-student",
      tags: ["Scholarship"],
      pillColor: "#cc0621",
    },
  ],
  skillCourseResources: {
    "c#": {
      link: "/course/c-beginner",
      title: "Free | C# | Midnight Pizza Fight",
      id: "course-midnight-pizza-fight",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    cpp: {
      link: "/course/beginner-cpp",
      title: "Free | C++ | Lethos",
      id: "course-beginner-cpp",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
    sfml: {
      link: "/course/pong-sfml",
      title: "Free | C++ | Pong",
      id: "course-sfml",
      tags: ["Course"],
      pillColor: "#b35a04",
    },
  },
};
