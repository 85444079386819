import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BaseActionController from "../BaseAction.controller";

class BasePageActionController<
  T extends BaseFlowController
> extends BaseActionController<T> {
  protected url: string;
  constructor(flow: T) {
    super(flow);
  }

  protected async takeAction(): Promise<void> {
    await ClientAppManager.instance.userFlowManager.router.push(this.url);
  }
}

export default BasePageActionController;
