import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import GoogleIcon from "@/public/assets/images/googleIcon.svg";
import { GoogleButton, StyledImage } from "./GoogleAuthButton.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { removeQueryParam } from "@/utils/urlHelper";

const GoogleOAuthButton = ({
  text,
  analyticsIntentText,
  variant,
  bgColor,
  color,
  highlight,
  width,
  redirectUrl,
  registerFrom,
}) => {
  const router = useRouter();
  const currentUrl = router.asPath;
  const [authUrl, setAuthUrl] = useState("");

  useEffect(() => {
    localStorage.setItem(
      "prev-url",
      redirectUrl || removeQueryParam(currentUrl, "popup")
    );
  }, [currentUrl]);

  const onClick = () => {
    if (registerFrom) {
      sessionStorage.setItem("registerFrom", registerFrom);
    } else {
      sessionStorage.removeItem("registerFrom");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const clientId = process.env.GOOGLE_CLIENT_ID;
      const redirectUri = `${window?.location?.protocol}//${window?.location?.host}/${process.env.GOOGLE_REDIRECT_URI}`;
      const scope = "profile email";
      const responseType = "code";
      const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=consent`;
      setAuthUrl(url);
    }
  }, []);

  return (
    <GoogleButton
      width={width}
      href={authUrl}
      highlight={highlight}
      isIconPill={variant === "iconpill"}
      data-analytics={[
        UIElements.BUTTON,
        `continue_with_google${
          analyticsIntentText ? `_${analyticsIntentText}` : ""
        }`,
      ]}
      bgColor={bgColor}
      color={color}
      onClick={onClick}
    >
      <StyledImage
        width={28}
        height={28}
        src={GoogleIcon}
        alt="Google icon"
        quality={10}
      />
      {variant !== "iconpill" && `${text} Google`}
    </GoogleButton>
  );
};

export default GoogleOAuthButton;
