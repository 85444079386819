import { NetworkManager } from "@/outscal-commons-frontend";
import { UserJobAlertStatusEnum } from "@/outscal-commons-frontend/Enums";
import LocalStorageManager from "./LocalStorageManager";

interface AlertFilters {
  [key: string]: any;
}

class JobAlertsManager {
  public async updateJobAlerts(
    alertFilters: AlertFilters,
    alertType: number,
    alertLocation: string,
    alertId: string | null
  ): Promise<void> {
    try {
      await NetworkManager.post(
        "/user-job-alerts/start",
        {
          jobAlert: {
            alert_filter: alertFilters,
            alert_type: alertType,
            alert_location: alertLocation,
          },
          alertId,
        },
        "",
        null,
        true
      );
    } catch (error) {
      console.error("Error submitting pending assessment:", error);
    }
  }

  public async createPostAuthAlert(): Promise<void> {
    try {
      const jobAlertData = LocalStorageManager.get("jobAlertData");

      if (!jobAlertData) {
        return;
      }

      const parsedData = JSON.parse(jobAlertData);
      if (parsedData && Object.keys(parsedData).length > 0) {
        const { alertFilters, alertType, alertLocation, alertId } = parsedData;
        await this.updateJobAlerts(
          alertFilters,
          alertType,
          alertLocation,
          alertId
        );

        LocalStorageManager.remove("jobAlertData");
      }
    } catch (error) {
      console.error("Error creating post-authentication alert:", error);
    }
  }
}

export default JobAlertsManager;
