import React from "react";
import styled from "styled-components";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import SearchInput from "./SearchInput";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { isMobile } from "@/hooks/useMobileBreakPoint";

export const ErrorText = styled.p`
  ${(p) => `color:${p.theme.colors.muiRed}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
  margin-top: 0px;
`;
const StyledDropdownHeader = styled(ColDiv)`
  margin: 8px;
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  padding: 8px;
  gap: 8px;
  background-color: ${(p) => p.theme.colors.backgroundGrey};
`;

const ClearAllButton = styled(Text)`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: ${(p) => p.theme.fontSize.xxs};
  border: 1px solid ${(p) => p.theme.colors[p.color]};
  &:hover {
    color: ${(p) => p.theme.colors[p.color]};
    background-color: ${(p) => p.theme.colors.white};
  }
`;

const CountText = styled(Text)`
  font-size: ${(p) => p.theme.fontSize.xs};
  color: ${(p) => p.theme.colors.themeBlue};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  text-align: center;
`;

const DropdownHeader = ({
  label,
  showSearchInput,
  searchTerm,
  setSearchTerm,
  primaryColor,
  secondaryColor,
  error,
  clearAll,
  closeIfOpen,
  hasFiltersApplied,
  totalOptionsLen,
  displayOptionsLen,
  filterAppliedLabel,
  selectedOptionsLen,
}) => {
  return (
    <StyledDropdownHeader>
      <RowDiv justifyContent="space-between" alignItems="baseline">
        <ColDiv>
          <Text fontSize="sm" fontWeight="medium">
            {label}
          </Text>
          {showSearchInput ? (
            <CountText>
              {selectedOptionsLen > 0
                ? `${selectedOptionsLen} ${filterAppliedLabel} selected`
                : `Showing ${displayOptionsLen}/${totalOptionsLen} ${filterAppliedLabel}`}
            </CountText>
          ) : null}
        </ColDiv>
        <RowDiv gap={"10px"}>
          {!isMobile && (
            <ClearAllButton
              color={"textGreen"}
              onClick={closeIfOpen}
              fontSize="sm"
              fontWeight="medium"
              data-analytics={[
                UIElements.BUTTON,
                `apply_${label.toLowerCase()}_job_filters`,
              ]}
            >
              Apply
            </ClearAllButton>
          )}
          {hasFiltersApplied && !isMobile && (
            <ClearAllButton
              color={"black"}
              onClick={clearAll}
              fontSize="sm"
              fontWeight="medium"
              data-analytics={[
                UIElements.BUTTON,
                `clear_${label.toLowerCase()}_job_filters`,
              ]}
            >
              Clear
            </ClearAllButton>
          )}
        </RowDiv>
      </RowDiv>
      {error && <ErrorText>{error}</ErrorText>}
      <SearchInput
        showSearchInput={showSearchInput}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        primaryColor={primaryColor}
      />
    </StyledDropdownHeader>
  );
};

export default DropdownHeader;
